/* Inventory.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  background: initial;
  padding-top: 2px;
  width: 300px;
  height: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
}




.button,
.link,
.option,
.dropdown-button {
  display: block;
  border-radius: 8px;
  background: linear-gradient(to bottom right, teal, purple);
  color: turquoise;
  padding: 10px;
  margin: 5px auto;
  cursor: pointer;
  width: 80%;
  max-width: 300px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.button:hover,
.link:hover,
.option:hover,
.dropdown-button:hover {
  background-color: darkslateblue;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}

.button:disabled,
.option:disabled,
.dropdown-button:disabled {
  cursor: not-allowed;
  background-color: darkslateblue;
  box-shadow: none;
}

.image-container {
  position: relative;
  margin-top: 10px;
}

.image {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.info {
  color: turquoise;
  margin-top: 5px;
  margin-bottom: 5px;
}

.inventory-list {
  list-style: none;
  padding: 0;
}

.link,
.button,
.option,
.dropdown-button {
  color: turquoise;
  margin-top: 5px;
  display: block;
  text-decoration: none;
}

.link:hover,
.button:hover,
.option:hover,
.dropdown-button:hover {
  text-decoration: none;
}

.options-container {
  display: flex;
  flex-direction: column;
}

.option,
.dropdown-button {
  background-color: teal;
  color: turquoise;
  border-radius: 8px;
  padding: 10px;
  margin: 5px auto;
  cursor: pointer;
  width: 80%;
  max-width: 250px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.option:hover,
.dropdown-button:hover {
  background-color: darkslateblue;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}

.option:disabled,
.dropdown-button:disabled {
  cursor: not-allowed;
  background-color: grey;
  box-shadow: none;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.inventory-tiles {
  display: flex;
  justify-content: space-between;
}

.inventory-tile {
  width: 48%; /* Adjust as needed */
}

.inventory-dropdown {
  margin: 10px;
}

.inventory-details {
  margin-top: 20px;
  color: turquoise; /* Added text color */
}

.inventory-details ul {
  list-style: none;
  padding: 0;
}

.inventory-details li {
  margin-bottom: 10px;
}

.sell-button {
  margin-left: 10px;
  border-radius: 4px;
  color: turquoise;
  background-color: darkslateblue;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  
}

.sell-button:hover {
  background-color: teal;
}
